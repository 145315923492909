import logo from './logo.svg';
import './App.css';
import Hero from './component/Hero';
import Nav from './component/Nav';
import Features from './component/Features';
import HowItWorks from './component/HowItWorks';
import GetStarted from './component/GetStarted';
import Footer from './component/Footer';

function App() {
  return (
    <>
      <Nav />
      <Hero />
      <Features />
      <HowItWorks />
      <GetStarted />
      <Footer />
    </>
  );
}

export default App;
