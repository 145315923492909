
function Hero() {
  return (
   <>
    {/* <!-- ------------------------------------------------------------------------------------ --> */}
    <section className="hero" style={{backgroundImage: `${'url(' + process.env.PUBLIC_URL + "/media/home.jpg" + ')'}`}}>
        <div className="container d-flex h-100 align-items-center gap-3">
            <div className="row w-100">
                <div className="col-lg-6 col-md-8 d-flex flex-column justify-content-center align-items-md-start align-items-center">
                    <h1>ProtectCord</h1>
                    <p className="w-md-75">Secure Your Discord: Verify Users, Block Alts, Bots &amp; VPNs with Our Top Security Bot!</p>
                    <a href="#" className="btn btn-primary py-3">Get Started</a>
                </div>
                <div className="col-lg-6 col-md-4 d-flex"><img src={`${process.env.PUBLIC_URL + "/media/ProtectCord1.png"}`} className="img-fluid" alt=""/></div>
            </div>
        </div>
    </section>
    {/* <!-- ------------------------------------------------------------------------------------ --> */}


   </>
  );
}

export default Hero;
