import React from 'react'

export default function HowItWorks() {
  return (
    <>
    {/* <!-- ----------------------------------How It Works ------------------------------------- --> */}
    <section className="how-it-works">
        <div className="container">
            <h1 className="text-center mb-sm-15 mb-3">How It Works</h1>
            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center position-relative mt-10">
                    <img src={`${process.env.PUBLIC_URL + "/media/how-it-work-img-1.png"}`} className="img-fluid rounded-3 m-auto z-index-1 position-relative" alt="" />
                    {/* <div className="w-100 h-100 bg-primary position-absolute rounded-1 mt-5 d-none d-md-block" style={{top: "15px",left: "30px"}} ></div> */}
                </div>
                <div className="col-lg-7 col-md-7 offset-md-1 d-flex flex-column justify-content-center offset-lg-1 mt-10">
                    <p className="text-gray-500 text-sm flex items-center justify-start">
                        Our cutting-edge Discord bot enhances your server's security by thoroughly verifying users, stopping alt
                        accounts, bot accounts, and users connecting via VPNs. Only genuine and quality members will join your
                        community.
                    </p>
                </div>
                </div>
                <div className="row flex-column-reverse flex-md-row">
                <div className="col-lg-7 col-md-7 d-flex flex-column justify-content-center mt-10">
                    <p className="text-gray-500 text-sm flex items-center justify-start">
                        Simply run the <small>/setup</small>
                        command and let the bot handle the rest. You can configure the channels as
                        you like. While the bot requires extensive permissions for optimal operation, you can tweak the settings if
                        you're discord-savvy. We're here to assist you with any adjustments to ensure seamless performance.
                    </p>
                </div>
                <div className="col-lg-4 col-md-4  offset-md-1 mt-10 col-sm-12 d-flex justify-content-center offset-lg-1 position-relative">
                    <img src={`${process.env.PUBLIC_URL + "/media/how-it-work-img-2.png"}`} className="img-fluid  rounded-3 m-auto z-index-1" alt="" />
                    {/* <div className="w-100 h-100 bg-primary position-absolute rounded-1 mt-5 d-none d-md-block" style={{top: "15px",left: "-30px"}} ></div> */}
                </div>
            </div>
        </div>
    </section>
    {/* <!-- ------------------------------------------------------------------------------------ --> */}

    </>
  )
}
