import React from 'react'

export default function Features() {
  return (
    <>
    {/* <!-- --------------------------------------Features-------------------------------------- --> */}
    <section className="features">
        <div className="container">
            <h1 className="text-center mb-sm-20 mb-10">Features</h1>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12 mb-md-9 mb-5">
                    <div className="feature-card">
                        <img src={`${process.env.PUBLIC_URL + "/media/f1.png"}`} className="img-fluid" alt=""/>
                        <div>
                        <h2 className="text-gray-100 fw-bold mt-4 mb-2">Thorough User Verification:</h2>
                        <h3 className="text-gray-300 fw-300 mt-4 mb-10"> Verify users through a dedicated website.</h3>
                        <a href='https://discord.com/oauth2/authorize?client_id=1239704447681302641&permissions=8&scope=bot' 
                        className="text-primary text-sm flex items-center justify-start fs-5">Invite Protectcord</a>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 mb-md-9 mb-5">
                    <div className="feature-card">
                        <img src={`${process.env.PUBLIC_URL + "/media/f2.png"}`} className="img-fluid" alt=""/>
                        <div>
                        <h2 className="text-gray-100 fw-bold mt-4 mb-2">Alt Account Blocking:</h2>
                        <h3 className="text-gray-300 fw-300 mt-4 mb-10"> Effectively stop alt accounts from joining.</h3>
                        <a href='https://discord.com/oauth2/authorize?client_id=1239704447681302641&permissions=8&scope=bot' 
                        className="text-primary text-sm flex items-center justify-start fs-5">Invite Protectcord</a>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 mb-md-9 mb-5">
                    <div className="feature-card">
                        <img src={`${process.env.PUBLIC_URL + "/media/f3.png"}`} className="img-fluid" alt=""/>
                        <div>
                        <h2 className="text-gray-100 fw-bold mt-4 mb-2">Bot Account Detection:</h2>
                        <h3 className="text-gray-300 fw-300 mt-4 mb-10">  Automated banning of detected bots.</h3>
                        <a href='https://discord.com/oauth2/authorize?client_id=1239704447681302641&permissions=8&scope=bot' 
                        className="text-primary text-sm flex items-center justify-start fs-5">Invite Protectcord</a>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 mb-md-9 mb-5">
                    <div className="feature-card">
                        <img src={`${process.env.PUBLIC_URL + "/media/f4.png"}`} className="img-fluid" alt=""/>
                        <div>
                        <h2 className="text-gray-100 fw-bold mt-4 mb-2">VPN Detection:</h2>
                        <h3 className="text-gray-300 fw-300 mt-4 mb-10"> Block users connecting via VPNs.</h3>
                        <a href='https://discord.com/oauth2/authorize?client_id=1239704447681302641&permissions=8&scope=bot' 
                        className="text-primary text-sm flex items-center justify-start fs-5">Invite Protectcord</a>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 mb-md-9 mb-5">
                    <div className="feature-card">
                        <img src={`${process.env.PUBLIC_URL + "/media/f5.png"}`} className="img-fluid" alt=""/>
                        <div>
                        <h2 className="text-gray-100 fw-bold mt-4 mb-2">Security Alerts:</h2>
                        <h3 className="text-gray-300 fw-300 mt-4 mb-10"> Receive security alerts (coming soon).</h3>
                        <a href='https://discord.com/oauth2/authorize?client_id=1239704447681302641&permissions=8&scope=bot' 
                        className="text-primary text-sm flex items-center justify-start fs-5">Invite Protectcord</a>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 mb-md-9 mb-5">
                    <div className="feature-card">
                        <img src={`${process.env.PUBLIC_URL + "/media/f6.png"}`} className="img-fluid" alt=""/>
                        <div>
                        <h2 className="text-gray-100 fw-bold mt-4 mb-2">User-Friendly Process:</h2>
                        <h3 className="text-gray-300 fw-300 mt-4 mb-10"> Easy verification process for members.</h3>
                        <a href='https://discord.com/oauth2/authorize?client_id=1239704447681302641&permissions=8&scope=bot' 
                        className="text-primary text-sm flex items-center justify-start fs-5">Invite Protectcord</a>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- ------------------------------------------------------------------------------------ --> */}
      
    </>
  )
}
