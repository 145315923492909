import React from 'react'

export default function Footer() {
  return (
    <>
    {/* <!-- -------------------------------------Footer------------------------------------ --> */}
    <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
            <img src={`${process.env.PUBLIC_URL + '/media/logo-dark.png'}`} alt="" className="logo w-125px"/>
            <p className='text-gray-500 p1 mt-7'> The best Discord bot to bootstrap and grow your Discord server</p>
            </div>
            <div className="col-lg-2 col-sm-4 mt-13">
              <h6>Modules</h6>
              <ul>
                <li><a href="#">Anti Bot</a></li>
                <li><a href="#">Anti VPN</a></li>
                <li><a href="#">Captcha</a></li>
                <li><a href="#">Welcomer</a></li>
                <li><a href="#">Auto Role</a></li>
              </ul>
            </div>
            <div className="col-lg-2 col-sm-4 mt-13">
              <h6>ProtectCord</h6>
              <ul>
                <li><a href="#">Premium</a></li>
                <li><a href="#">Support Server</a></li>
                <li><a href="#">About Us</a></li>
              </ul>
            </div>
            <div className="col-lg-2 col-sm-4 mt-13">
              <h6>SneakyHub</h6>
              <ul>
                <li><a href="#">Terms of use</a></li>
                <li><a href="#">Privacy Policy</a></li>
                <li><a href="#">Bug Bounty</a></li>
              </ul>
            </div>
          </div>
          <div className="d-flex sub-footer">
            <p className='w-100 text-center m-0'>© 2024 ProtectCord. All rights reserved.</p>
          </div>
        </div>
    </footer>
    {/* <!-- ------------------------------------------------------------------------------------ --> */}
      
    </>
  )
}
