import React from 'react'

export default function GetStarted() {
  return (
    <>
    {/* <!-- -------------------------------------Get Started------------------------------------ --> */}
    <section className="get-started" style={{backgroundImage: `${'url(' + process.env.PUBLIC_URL + "/media/contact.jpg" + ')'}`}}>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 offset-lg-3 col-md-9 offset-md-3  col-sm-8 offset-sm-4 d-flex flex-column align-items-sm-start gap-2">
                    <h1 className="">Get Started</h1>
                    <p className="text-white text-sm flex items-center justify-start">
                        To start using ProtectCord on your server, simply invite the bot using the button below:    
                    </p>
                    <a href="#" className="btn btn-white py-3">Invite ProtectCord</a>
                    <p className="text-white text-sm flex items-center justify-start help-text mt-4">Need help? Join our official <a href="https://discord.com/invite/2mgZaZxjdY" target="_blank">Support
                        Discord.</a></p>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- ------------------------------------------------------------------------------------ --> */}
      
    </>
  )
}
