import React, { useState } from 'react'

export default function Nav() {
    const [isVisible, setIsVisible] = useState(false);

    const toggleDiv = () => {
        setIsVisible(!isVisible);
    };
  return (
    <>
    {/* <!-- ------------------------------------Navigation-------------------------------------- --> */}
    <nav className="top-nav">
        <div className="container">
            <div className="w-100 d-flex justify-content-between align-items-center gap-3">
                <div className="nav-left-side">
                    <img src={`${process.env.PUBLIC_URL + '/media/logo-dark.png'}`} alt="" className="logo w-100px"/>
                    <ul className="d-md-flex d-none">
                        <li><a href="#">Features</a></li>
                        <li><a href="#">How it work</a></li>
                        <li><a href="#">Need help?</a></li>
                    </ul>
                </div>
                <div className="nav-right-side">
                    <a href="#" className="btn btn-primary py-md-3 py-2 px-4 px-md-7">Login <span className="d-sm-inline d-none">with ProtectCord</span></a>
                    <img src={`${process.env.PUBLIC_URL + '/media/List.svg'}`} onClick={toggleDiv} alt="" className="h-40px ms-1 px cursor-pointer d-md-none d-inline"/>
                </div>
            </div>
        </div>
    </nav>
    {/* <!-- ----------------------------------------Hero---------------------------------------- --> */}
    <div className={`mobile-menu p-3 ${isVisible ? 'open' : ''}`}>
    <img src={`${process.env.PUBLIC_URL + '/media/close.svg'}`} alt="" onClick={toggleDiv} className="close-btn"/>
    <img src={`${process.env.PUBLIC_URL + '/media/logo-dark.png'}`} alt="" className="logo w-125px"/>
    <ul>
        <li><a href="#">Features</a></li>
        <li><a href="#">How it work</a></li>
        <li><a href="#">Need help?</a></li>
    </ul>

    </div>
    
    </>
  )
}
